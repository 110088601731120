import { PMT, CUMIPMT, PV } from "./CalculatorExcelFunctions";
import { REPAYMENT_FREQUENCY } from './calculatorConstants';
import { roundCents, roundNumber } from '../utils/utils'


export const getRepaymentFrequencyData = (frequency) => {
    let repaymentType = REPAYMENT_FREQUENCY[0]
    for( let i = 0; i< REPAYMENT_FREQUENCY.length; i++){
        if( REPAYMENT_FREQUENCY[i].label === frequency){
            repaymentType = REPAYMENT_FREQUENCY[i];
            break;
        }
    }
    return repaymentType;
}

// Mortgage costs annual/monthly calculator
export const calculateOngoingCosts = (costs = []) => {
    let annual = 0;
    let monthly = 0;

    for (let i = 0; i < costs.length; i++) {
        const cost = costs[i];
        monthly += cost.amount / cost.frequency.data;
        annual += cost.amount / (cost.frequency.data / 12);
    }

    return { annual, monthly }
}


export const calculateBracket = (value, data, fees) => {

    const { brackets, quantise = 1, nearest = 1, cap = 0, incremental = false } = data;

    // Check conditions:
    let roundFn = "round";
    let result = 0;
    let inc = incremental;

    for (let i = 0; i < brackets.length; i++) {
        // for each of the brackets
        const bracket = brackets[i];
        const { from = 0, to = 0, value: val = 0, type = "percent", add = 0, m = 0, c = 0, a = 0, b = 0 } = bracket;

        // override incremental?
        if (bracket.incremental !== undefined) {
            inc = bracket.incremental
        } else {
            inc = incremental;
        }

        
        let reference = undefined;
        if( bracket.reference !== undefined){
            // find the reference key from the fee object
            fees.forEach((element) => {
                if( element.value && element.name === bracket.reference){
                    reference = element.value;
                }
            });
        }

        roundFn = bracket.roundFn || "round";
        let bracketAmount;

        // trigger on active bracket
        if (value >= from) {

            // part bracket > from and < to, otherwise it is complete bracket
            let partBracket = value <= to || to === 0;

            // calculate the value within this bracket (check cap)
            if (partBracket) {
                bracketAmount = Math.ceil((value - from));
            } else {
                // all of the bracket
                bracketAmount = to - from;
            }

            let bracketAmount_quantised = Math.ceil(bracketAmount / quantise) * quantise;

            switch (type) {
                case "multiply":
                    if (inc) {
                        if(reference){
                            result += reference * val
                        }else{
                            result += bracketAmount_quantised * val
                        }

                    } else {
                        if(reference){
                            result = reference * val

                        }else{
                            result = value * val
                        }
                    }

                    break;

                    case "multiplySlope":
                        let multiply = (value * m) + c;
                    if (inc) {
                        if(reference){
                            result += reference * multiply
                        }else{
                            result += bracketAmount_quantised * multiply
                        }

                    } else {
                        if(reference){
                            result = reference * multiply
                        }else{
                            result = value * multiply
                        }
                    }
                    break;


                case "fixed":
                    if (inc) {
                        if(reference){
                            result += reference;
                        }else{
                            result += val;
                        }
                    } else {
                        if(reference){
                            result = reference;
                        }else{
                            result = val;
                        }
                    }
                    break;

                    case "slope":

                    //linear slope y = m* x + c
                    if (inc) {
                        if(reference){
                            result += (reference * m) + c;
                        }else{
                            result += (value * m) + c;
                        }
                    } else {
                        if(reference){
                            result = (reference * m) + c;
                        }else{
                            result = (value * m) + c;
                        }
                    }
                    break;

                case "quadratic":
                    if (inc) {
                            result += a * Math.pow(value/c, 2) + b*value/c
                    } else {
                            result = a * Math.pow(value/c, 2) + b*value/c
                    }
                    break;


                case "percent":
                default:
                    if (inc) {
                        if(reference){
                            result += reference * (val / 100);
                        }else{
                            result += bracketAmount_quantised * (val / 100);
                        }
                    } else {
                        if(reference){
                            result = reference * (val / 100);
                        }else{
                            result = value * (val / 100);
                        }
                    }

                    if (add) {
                        result += add;
                    }
                    break;

            }
        }
    }

    // Rounding
    const n = 1 / nearest;
    switch (roundFn) {
        case "floor":
            result = Math.floor(result * n) / n;
            break;
        case "round":
        default:
            result = Math.round(result * n) / n;
    }

    // capping
    if (cap && cap > 0) {
        result = Math.min(cap, result);
    }

    return result;
}

// convert annual rates to monthly
export const convertAnnualToMonth = (annual) => {
    return Math.pow((1 + annual), 1 / 12) - 1;
}


export const interestPrincipalRatioData = (interestRate, loanValue) => {
    const ipr = [];
    const monthlyInterestRate = Number(interestRate) / (100 * 12);


    for (var i = 1; i <= 40; i++) {
        //PMT = (interestRate, years, presentValue, futureValue = 0, type = 0) 
        // let monthly = PMT( monthlyInterestRate, i*12, loanValue);
        // console.log("MONTHLY:", monthly)
        let monthly = PMT(Math.abs(monthlyInterestRate), i * 12, Number(loanValue));
        if (monthlyInterestRate < 0) monthly *= -1;

        monthly = roundCents(Math.abs(monthly));
        const totalCost = monthly * i * 12;

        //export const CUMIPMT = (rate, periods, value, start, end, type) 
        let totalInterest = Math.abs(CUMIPMT(Math.abs(monthlyInterestRate), i * 12, Number(loanValue)));
        if (monthlyInterestRate < 0) totalInterest *= -1;

        let interest2Principal = totalInterest / totalCost;
        interest2Principal = roundNumber(interest2Principal, 3);
        interest2Principal *= 1000;
        interest2Principal /= 10;

        ipr.push({ year: i, monthly, totalInterest, totalCost, ipr: interest2Principal })
    }
    return ipr;
}

export const principalValue = (interestRate = 0, term = 0, repayment = 0) => {
    return Math.abs(PV(Number(interestRate) / (12 * 100), Number(term), Number(repayment)));
}

export const principalValueSplit = (splitMortgage, mortgagePrimary, mortgageSecondary, repayment = 0) => {
    let pv = 0;
    if( splitMortgage){
        
        // hellish calculation
        // assume on initial monthly repayment?
        
        // primary
        pv = Math.abs(PV(Number(mortgagePrimary.interestRate) / (12 * 100), Number(mortgagePrimary.term), Number(repayment)));
        // secondary
        pv += Math.abs(PV(Number(mortgageSecondary.interestRate) / (12 * 100), Number(mortgageSecondary.term), Number(repayment)));

    }else{
        pv = Math.abs(PV(Number(mortgagePrimary.interestRate) / (12 * 100), Number(mortgagePrimary.term), Number(repayment)));
    }
    return pv
}


//monthlyRepayment
export const monthlyRepayment = (interestRate = 0, term = 0, loanAmount = 0) => {
    let totalPayments = Number(term) ;
    let monthlyInterestRate = Number(interestRate) / (12 * 100);
    let monthly = PMT(monthlyInterestRate, totalPayments, -1 * Number(loanAmount));
    monthly = roundCents(monthly);
    return monthly;
}


export const autoRange = (value, min, max) => {
    const OVER_RANGE = 0.2;
    const range = [min, max];
    if (value > max) {
        range[1] = value + Math.abs(value * OVER_RANGE);
    } else if (value < min) {
        range[0] = value - Math.abs(value * OVER_RANGE);
    }
    return range;

}



export const matchConditions = ( state, obj ) => {

    let conditions = obj.conditions ? obj.conditions : [];
    let exclusions = obj.exclusions ? obj.exclusions : [];
    // does it match all the conditions?
    let hasConditions = true;
    if ( conditions.length > 0 ){
        conditions.forEach((condition) => {
            switch (typeof condition) {
                case "string":
                        hasConditions = hasConditions && state[condition]
                        break;
                case "object":
                    // presume a single key value pair!!
                    for (const key in condition) {
                        hasConditions = hasConditions && state[key] === condition[key];
                    }
                    break;
                default:
                    console.log("ERROR: unknown consession condition:", condition);
                    break;
                }
        })
    }

    if( !hasConditions) return false;
    
    let hasExclusions = false;
    if( exclusions.length ){
        exclusions.forEach((item) => {
            hasExclusions = state[item]
        })
        exclusions.forEach((exclusion) => {
            switch (typeof exclusion) {
                case "string":
                    hasExclusions = hasExclusions || state[exclusion]
                        break;
                case "object":
                    // presume a single key value pair!!
                    for (const key in exclusion) {
                        hasExclusions =  hasExclusions || state[key] === exclusion[key];
                    }
                    break;
                default:
                    console.log("ERROR: unknown consession exclusion:", exclusion);
                    break;
                }
        })
    }

    return hasConditions && !hasExclusions;
}

